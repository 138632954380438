<template>
    <el-card class="box-card">
        <div slot="header"  class="tit">
            <h3 class="inBlock">模板使用情况</h3>
            <back></back>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
                prop="id"
                label="商品id"
                >
            </el-table-column>
            <el-table-column
                prop="name"
                label="商品名称"
               >
            </el-table-column>
            <el-table-column
                prop="cat_name"
                label="商品分类名称">
            </el-table-column>
            <el-table-column
                prop="addtime"
                label="商品添加时间">
            </el-table-column>
            <el-table-column label="状态">
                <div class="Dflex" slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.status == 1" type="success">已上架</el-tag>
                    <el-tag size="mini" v-else effect="dark" type="info">已下架</el-tag>
                </div>
            </el-table-column>
        </el-table>
        <el-pagination
            style="margin-top: 20px"
            @current-change="CrtChg"
            :current-page="page"
            layout="prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
    </el-card>
</template>
<script>
import { useExpressTemplate } from "@/api/api.js";
export default {
    data(){
        return{
            id:"",
            total:1,
            page:1,
            tableData:[],
        }
    },
    methods:{
        CrtChg(e) {
            this.form.page = e;
            this.useExpressTemplate();
        },
        // 获取模板使用详情
        async useExpressTemplate(id) {
            const { data } = await useExpressTemplate(id);
            if (data.code != 200) return this.$message.error(data.data);
            this.tableData = data.data.data;
            console.log(this.tableData)
            this.tableData.forEach((item) => {
                item.addtime = this.$timeTo.time3(item.addtime);
            });
            this.total = data.data.total;
        },
    },
    created(){
        if (this.$route.query.id) {
            this.useExpressTemplate({
                id: this.$route.query.id
            });
        }
    }
}
</script>
<style lang="less" scoped>

</style>